import {testIdProps} from '@github-ui/test-id-props'
import {Box} from '@primer/react'
import {memo} from 'react'

import {TextPlaceholder} from '../../../components/common/placeholders'
import {
  useGroupsVisiblePagination,
  useSinglyGroupedItemsVisiblePagination,
  useUngroupedVisiblePagination,
} from '../../../components/common/use-visible-pagination'
import {StyledTableCell} from '../../../components/react_table/table-cell'
import {ROADMAP_NUMBER_COLUMN_WIDTH} from '../../../components/roadmap/constants'
import {useRoadmapTableWidth} from '../../../hooks/use-roadmap-settings'
import {
  type GroupId,
  isPageTypeForGroupedItems,
  type PageType,
  pageTypeForGroups,
  pageTypeForUngroupedItems,
} from '../../../state-providers/memex-items/queries/query-keys'
import {roadmapCellSx, roadmapRowSx} from './roadmap-table-layout'

export const PLACEHOLDER_ROW_COUNT = 5

export const RoadmapPagination: React.FC<{pageType: PageType}> = ({pageType}) => {
  if (pageType === pageTypeForUngroupedItems) {
    return <UngroupedPagination />
  } else if (pageType === pageTypeForGroups) {
    return <GroupsPagination />
  } else if (isPageTypeForGroupedItems(pageType)) {
    return <GroupedItemsPagination groupId={pageType.groupId} />
  } else {
    // The roadmap view doesn't support grouped item batches or secondary groups
    return null
  }
}

const UngroupedPagination: React.FC = () => {
  const {ref, hasNextPage} = useUngroupedVisiblePagination()
  return (
    <div ref={ref} {...testIdProps(`roadmap-pagination`)}>
      {!hasNextPage ? null : [...Array(PLACEHOLDER_ROW_COUNT).keys()].map(key => <PlaceholderRow key={key} />)}
    </div>
  )
}

const GroupsPagination: React.FC = () => {
  const {ref, hasNextPage} = useGroupsVisiblePagination()
  return (
    <div ref={ref} {...testIdProps(`roadmap-pagination`)}>
      {!hasNextPage ? null : [...Array(PLACEHOLDER_ROW_COUNT).keys()].map(key => <PlaceholderRow key={key} />)}
    </div>
  )
}

const GroupedItemsPagination: React.FC<{groupId: GroupId}> = ({groupId}) => {
  const {ref, hasNextPage} = useSinglyGroupedItemsVisiblePagination(groupId)
  return (
    <div ref={ref} {...testIdProps(`roadmap-pagination-${groupId}`)}>
      {!hasNextPage ? null : [...Array(PLACEHOLDER_ROW_COUNT).keys()].map(key => <PlaceholderRow key={key} />)}
    </div>
  )
}

const PlaceholderRowUnmemoized: React.FC = () => {
  const tableWidth = useRoadmapTableWidth()
  return (
    <Box className="roadmap-row" role="row" sx={roadmapRowSx} {...testIdProps('placeholder-row')}>
      <StyledTableCell
        className="roadmap-table-cell"
        sx={roadmapCellSx}
        style={{
          height: '100%',
          position: 'sticky',
          left: 0,
          width: tableWidth,
          paddingLeft: `${ROADMAP_NUMBER_COLUMN_WIDTH}px`,
        }}
      >
        <TextPlaceholder minWidth={80} maxWidth={200} {...testIdProps('placeholder')} />
      </StyledTableCell>
    </Box>
  )
}

const PlaceholderRow = memo(PlaceholderRowUnmemoized)

try{ RoadmapPagination.displayName ||= 'RoadmapPagination' } catch {}
try{ UngroupedPagination.displayName ||= 'UngroupedPagination' } catch {}
try{ GroupsPagination.displayName ||= 'GroupsPagination' } catch {}
try{ GroupedItemsPagination.displayName ||= 'GroupedItemsPagination' } catch {}
try{ PlaceholderRowUnmemoized.displayName ||= 'PlaceholderRowUnmemoized' } catch {}
try{ PlaceholderRow.displayName ||= 'PlaceholderRow' } catch {}